<template>
  <div class="overlay">
    <page-title>{{ i18n.MENU.order }}</page-title>
    <validation-observer v-slot="{ handleSubmit }">
      <form action="#" @submit.prevent="handleSubmit(orderProcess)">
        <div class="width">
          <dl class="user-info">
            <dt class="user-info__title">{{ i18n.ORDER.NAME }}:</dt>
            <dd class="user-info__text">
              <validation-provider rules="required" v-slot="{ errors }">
                <input
                  type="text"
                  disabled="disabled"
                  name="order_name"
                  v-model="orderName"
                  class="order__text"
                />
                <label class="error">{{ i18n['VALIDATE'][errors[0]] }}</label>
              </validation-provider>
            </dd>
            <dt class="user-info__title">
              <a
                target="_blank"
                href="http://www.ukrstat.gov.ua/menu/edrpou.htm"
                >{{ i18n.ORDER.EDRPOU }}</a
              >:
            </dt>
            <dd class="user-info__text">
              <input
                type="text"
                disabled="disabled"
                name="order_name"
                v-model="orderEdrpou"
                class="order__text"
              />
            </dd>
            <dt class="user-info__title">{{ i18n.ORDER.PHONE }}:</dt>
            <dd class="user-info__text">
              <validation-provider rules="required" v-slot="{ errors }">
                <cleave
                  type="text"
                  disabled="disabled"
                  name="order_phone"
                  :options="phoneOptions"
                  :raw="false"
                  v-model="orderPhone"
                  placeholder="+380 __ ___ ____"
                  class="order__text"
                />
                <label class="error">{{ i18n['VALIDATE'][errors[0]] }}</label>
              </validation-provider>
            </dd>
            <dt class="user-info__title">{{ i18n.ORDER.ADDRESS }}:</dt>
            <dd class="user-info__text">
              <validation-provider rules="required|email" v-slot="{ errors }">
                <input
                  type="text"
                  disabled="disabled"
                  name="order_email"
                  v-model="orderEmail"
                  class="order__text"
                />
                <label class="error">{{ i18n['VALIDATE'][errors[0]] }}</label>
              </validation-provider>
            </dd>
            <dt class="user-info__title">{{ i18n.ORDER.CITY }}:</dt>
            <dd class="user-info__text">
              <input type="text" v-model="orderCity" class="order__text" />
            </dd>
            <dt class="user-info__title">{{ i18n.ORDER.ADDITIONAL }}:</dt>
            <dd class="user-info__text">
              <textarea v-model="orderText" class="order__text"></textarea>
            </dd>
            <dt class="user-info__title">{{ i18n.ORDER.ADD_FILE }}:</dt>
            <dd class="user-info__text file-input-container">
              <validation-provider
                :rules="{ validate: () => fileValidation }"
                v-slot="{ errors }"
              >
                <label class="file-input-label" for="file-input">{{
                  i18n.ORDER.CHOOSE_FILE
                }}</label>
                <input
                  type="file"
                  id="file-input"
                  class="file-input"
                  @change="validateFile"
                  ref="fileInput"
                />
                <span class="file-name" id="file-name">{{ fileName }}</span>
                <label class="error error-file" v-if="fileError">{{
                  fileError
                }}</label>
                <label class="error" v-else-if="errors.length">{{
                  errors[0]
                }}</label>
              </validation-provider>
            </dd>
          </dl>
          <div class="size">
            <div class="size__title">
              {{ i18n.ORDER.SELECT_SIZE }}
            </div>
            <div class="size__text">
              {{ i18n.ORDER.W }}
              <validation-provider
                :rules="`required|number_between:${minWidth},${maxWidth}`"
                v-slot="{ errors }"
              >
                <input
                  type="number"
                  step="1"
                  v-model="order_width"
                  class="size__field"
                />
                <label class="error">
                  <error-label
                    :min="minWidth"
                    :max="maxWidth"
                    :label="i18n['VALIDATE'][errors[0]]"
                  />
                </label>
              </validation-provider>
              - {{ i18n.ORDER.H }}
              <validation-provider
                :rules="`required|number_between:${minLength},${maxLength}`"
                v-slot="{ errors }"
              >
                <input
                  type="number"
                  step="1"
                  v-model="order_height"
                  class="size__field"
                />
                <label class="error">
                  <error-label
                    :min="minLength"
                    :max="maxLength"
                    :label="i18n['VALIDATE'][errors[0]]"
                  />
                </label>
              </validation-provider>
              {{ i18n.ORDER.MM }}
            </div>
          </div>

          <div class="size">
            <div class="size__title" v-html="i18n.ORDER.SELECT_WING" />
            <div class="size__text">
              {{ i18n.ORDER.SELECT_WING_SIZE }}
              <validation-provider
                :rules="`number_between:${minWidth},${maxWidth}`"
                v-slot="{ errors }"
              >
                <input
                  type="number"
                  step="1"
                  v-model="order_wing"
                  class="size__field"
                />
                <label class="error">
                  <error-label
                    :min="minWidth"
                    :max="maxWidth"
                    :label="i18n['VALIDATE'][errors[0]]"
                  />
                </label>
              </validation-provider>
              {{ i18n.ORDER.MM }}
            </div>
          </div>

          <div class="size">
            <div class="size__title" v-html="i18n.ORDER.SELECT_BOTH_SIZE" />
            <div class="size__text">
              {{ i18n.ORDER.W }}
              <validation-provider
                :rules="`number_between:${minWidth},${maxWidth}`"
                v-slot="{ errors }"
              >
                <input
                  type="number"
                  step="1"
                  v-model="order_both_width"
                  class="size__field"
                />
                <label class="error">
                  <error-label
                    :min="minWidth"
                    :max="maxWidth"
                    :label="i18n['VALIDATE'][errors[0]]"
                  />
                </label>
              </validation-provider>
              - {{ i18n.ORDER.H }}
              <validation-provider
                :rules="`number_between:${minLength},${maxLength}`"
                v-slot="{ errors }"
              >
                <input
                  type="number"
                  step="1"
                  v-model="order_both_height"
                  class="size__field"
                />
                <label class="error">
                  <error-label
                    :min="minLength"
                    :max="maxLength"
                    :label="i18n['VALIDATE'][errors[0]]"
                  />
                </label>
              </validation-provider>
              {{ i18n.ORDER.MM }}
            </div>
          </div>

          <div class="additionaly">
            <!-- standard and "minimum size" text -->
            <!-- {{ i18n.ORDER.DESCRIPTION({modelName, modelWidth, modelLength}) }} -->
            <!-- <br> -->
            <!-- "maximum size" text -->
            <div v-html="maxSize" />
          </div>
          <!--
          <div class="blade">
            <p>
              <input type="radio"
                     id="inside_1"
                     name="inside"
                     value="inside_default">
              <label for="inside_1">Вставне дверне заповнення</label>
            </p>
            <p>
              <input type="radio"
                     id="inside_2"
                     value="inside_one"
                     name="inside">
              <label for="inside_2">Одностороннє накладне дверне заповнення</label>
            </p>
          </div>
          -->
          <div class="order-panel">
            <p>
              <input
                type="checkbox"
                class="custom-checkbox"
                v-model="orderPanel"
                id="panel"
              />
              <label for="panel">{{ i18n.ORDER.COMPLETE }}</label>
            </p>
          </div>
          <div class="order-footer">
            <button :disabled="!fileValidation" type="submit">
              {{ i18n.ORDER.CHECKOUT }}
            </button>
          </div>
        </div>
      </form>
    </validation-observer>
  </div>
</template>

<script>
import PageTitle from '@/components/page-title/page-title.vue';
import { API } from '@/constants/api';
import axios from 'axios';
import { mapGetters } from 'vuex';
import {
  ValidationProvider,
  ValidationObserver,
  extend,
  validate
} from 'vee-validate';
import { required, min_value, max_value, email } from 'vee-validate/dist/rules';
import Cleave from 'vue-cleave-component';
import 'cleave.js/dist/addons/cleave-phone.ua.js';
import ErrorLabel from '@/components/shared/error-label';
import { drawPNG } from '@/utils/picture';

extend('required', {
  ...required,
  message: 'REQUIRED'
});

extend('min_value', min_value);
extend('max_value', max_value);

extend('number_between', {
  async validate(value, { min_value, max_value }) {
    const res = await validate(
      value,
      `min_value:${parseInt(min_value, 10)}|max_value:${parseInt(
        max_value,
        10
      )}`
    );
    return res.valid;
  },
  params: ['min_value', 'max_value'],
  message: 'BETWEEN'
});

extend('email', {
  ...email,
  message: 'EMAIL'
});

export default {
  computed: {
    ...mapGetters('config', [
      'code',
      'shape',
      'model',
      'mirrored',
      'color',
      'house'
    ]),
    ...mapGetters('auth', ['isAuthenticated']),
    ...mapGetters('session', ['i18n']),
    minWidth() {
      return parseInt(this.model.min_model_width, 10) || 1150;
    },
    maxWidth() {
      const isAluminium = this.color[this.house].is_alu !== '0';
      if (isAluminium) {
        return 1200;
      }
      return 896;
    },
    minLength() {
      return parseInt(this.model.min_model_height, 10) || 2300;
    },
    maxLength() {
      const isAluminium = this.color[this.house].is_alu !== '0';
      if (isAluminium) {
        return 2300;
      }
      return 2146;
    },
    modelName() {
      return this.model.title;
    },
    modelWidth() {
      return this.model.min_model_width || 1150;
    },
    modelLength() {
      return this.model.min_model_height || 2300;
    },
    maxSize() {
      return this.i18n.ORDER.MAX_SIZE;
    }
  },
  components: {
    PageTitle,
    ValidationProvider,
    ValidationObserver,
    Cleave,
    ErrorLabel
  },
  created() {
    if (this.isAuthenticated) {
      this.loadUserData();
    }
  },
  methods: {
    validateFile(event) {
      const file = event.target.files[0];
      if (file) {
        this.fileName = file.name;
        this.fileext =
          '.' +
          file.name
            .split('.')
            .pop()
            .toLowerCase();

        if (this.exclude_ext.includes(this.fileext)) {
          this.fileError = this.i18n.VALIDATE.FILE_EXT.replace(
            '${ext}',
            this.fileext
          );
          this.fileValidation = false;
          return;
        }

        const maxSize = this.max_upload_filesize * 1024 * 1024;
        if (file.size > maxSize) {
          this.fileError = this.i18n.VALIDATE.FILE_SIZE.replace(
            '${max_size}',
            this.max_upload_filesize
          );
          this.fileValidation = false;
          return;
        }

        this.fileError = null;
        this.fileValidation = true;

        // Конвертация файла в base64
        const reader = new FileReader();
        reader.onload = e => {
          this.filedata = e.target.result;
        };
        reader.onerror = () => {
          this.fileError = 'Ошибка при чтении файла';
        };
        reader.readAsDataURL(file);
      }
    },
    async loadUserData() {
      let response;
      try {
        response = await axios.get(API.USER_INFO);
      } catch (e) {
        console.error(e);
      }
      if (response?.data?.success) {
        this.orderName = response.data.name;
        if (response.data.edrpou) {
          this.orderEdrpou = response.data.edrpou;
        }
        this.orderCity = response.data.city;
        this.orderEmail = response.data.email;
        this.orderPhone = response.data.phone;
        this.exclude_ext = response.data.exclude_ext;
        this.max_upload_filesize = response.data.max_upload_filesize;
      }
    },
    async getPictures() {
      const side = this.color.type === 'complete' ? 'outside' : this.color.type;
      await this.$store.dispatch('config/CONFIG_SET_HOUSE_VIEW', side);

      const svgImage1 = document.querySelector(
        'svg.configurator__door--outside'
      );
      const svgImage2 = document.querySelector(
        'svg.configurator__door--inside'
      );
      const svgImage1Box = svgImage1.getBBox();
      const svgWidth = parseInt(svgImage1Box.width, 10);
      const svgHeight = parseInt(svgImage1Box.height, 10);
      const serializer1 = await new XMLSerializer().serializeToString(
        svgImage1
      );
      const serializer2 = await new XMLSerializer().serializeToString(
        svgImage2
      );
      const base64String1 =
        'data:image/svg+xml;base64,' +
        window.btoa(unescape(encodeURIComponent(serializer1)));
      const base64String2 =
        'data:image/svg+xml;base64,' +
        window.btoa(unescape(encodeURIComponent(serializer2)));

      let base64PNG1;
      let base64PNG2;
      const isFlip = this.mirrored?.mirrored_type === 'mirrored' || false;

      try {
        base64PNG1 = await drawPNG({
          svg: base64String1,
          width: svgWidth,
          height: svgHeight,
          flip: isFlip
        });
      } catch (e) {
        console.error(e);
      }

      try {
        base64PNG2 = await drawPNG({
          svg: base64String2,
          width: svgWidth,
          height: svgHeight,
          flip: !isFlip
        });
      } catch (e) {
        console.error(e);
      }

      if (!base64PNG1 || !base64PNG2) {
        return null;
      }

      await this.$nextTick();
      return {
        outside: base64PNG1,
        inside: base64PNG2
      };
    },
    async orderProcess() {
      const pictures = await this.getPictures();
      axios
        .post(API.POST_ORDER, {
          params: {
            name: this.orderName,
            edrpou: this.orderEdrpou,
            phone: this.orderPhone,
            email: this.orderEmail,
            city: this.orderCity,
            text: this.orderText,
            filedata: this.filedata,
            fileext: this.fileext,
            width: Number(this.order_width),
            length: Number(this.order_height),
            widthBoth: Number(this.order_both_width)
              ? Number(this.order_both_width)
              : 0,
            lengthBoth: Number(this.order_both_height)
              ? Number(this.order_both_height)
              : 0,
            wing: Number(this.order_wing),
            panel: this.orderPanel,
            code: this.code,
            door: pictures
          }
        })
        .then(response => {
          if (response.data.success === true) {
            this.orderText = '';
            this.orderPanel = '';
            this.order_width = '';
            this.order_height = '';
            this.order_both_width = '';
            this.order_both_height = '';
            this.order_wing = '';
            this.fileValidation = true;
            this.fileError = '';
            this.filedata = '';
            this.fileext = '';
            this.fileName = '';
          }
          this.$swal({
            title: 'Інформація',
            text: response.data.message,
            width: 375,
            confirmButtonText: 'OK',
            confirmButtonColor: '#C70552',
            customClass: {
              header: 'sweet-modal__header',
              title: 'sweet-modal__title',
              popup: 'sweet-modal__popup',
              content: 'sweet-modal__content',
              actions: 'sweet-modal__actions',
              confirmButton: 'sweet-modal__ok-button'
            }
          });
        });
    }
  },
  data() {
    return {
      orderName: '',
      orderEdrpou: '',
      orderPhone: '',
      orderEmail: '',
      orderCity: '',
      orderText: '',
      orderPanel: true,
      order_wing: '',
      order_width: '',
      order_height: '',
      order_both_width: '',
      order_both_height: '',
      exclude_ext: '',
      max_upload_filesize: '',
      fileError: '',
      filedata: '',
      fileext: '',
      fileValidation: true,
      fileName: '',

      phoneOptions: {
        phone: true,
        phoneRegionCode: 'UA'
      }
    };
  },
  metaInfo() {
    return {
      title: this.i18n.MENU.order,
      meta: [
        { vmid: 'description', property: 'description', content: 'Замовити' },
        { vmid: 'og:title', property: 'og:title', content: 'Замовити' },
        {
          vmid: 'og:description',
          property: 'og:description',
          content: 'Замовити'
        }
      ]
    };
  }
};
</script>

<style lang="scss">
.width {
  margin: 20px 30px 0;
}
.order {
  margin: 12px 0 0;
  &__text {
    padding: 15px;
    background: #272c30;
    border: 1px solid #7d8b97;
    box-sizing: border-box;
    border-radius: 3px;
    width: 100%;
    font: 15px/18px 'ProximaNovaRegular', sans-serif;
    color: #ffffff;
    &:disabled {
      opacity: 0.3;
    }
  }
}
textarea.order__text {
  height: 110px;
  resize: vertical;
}
.user-info {
  margin: 0;
  padding: 0;
  font: 16px/200% 'ProximaNovaBold', sans-serif;
  overflow: hidden;
  color: #ffffff;
  &__title {
    margin: 15px 0 0;
    padding: 0 10px 0 0;
    float: left;
    clear: left;
    a {
      color: #ffffff;
    }
  }
  &__text {
    margin: 0;
    padding: 0;

    color: #c2cfd3;
  }
}
.blade {
  font: 15px/18px 'ProximaNovaRegular', sans-serif;
  color: #ffffff;
}
[type='radio']:checked,
[type='radio']:not(:checked) {
  position: absolute;
  left: -9999px;
}
[type='radio']:checked + label,
[type='radio']:not(:checked) + label {
  position: relative;
  padding: 4px 40px 0 40px;
  cursor: pointer;
  font: 15px/18px 'ProximaNovaRegular', sans-serif;
  color: #ffffff;
  display: flex;
}
[type='radio']:checked + label:before,
[type='radio']:not(:checked) + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 24px;
  height: 24px;
  border: 1px solid #7d8b97;
  border-radius: 100px;
}
[type='radio']:checked + label:after,
[type='radio']:not(:checked) + label:after {
  content: '';
  width: 10px;
  height: 10px;
  background: #c70552;
  position: absolute;
  top: 8px;
  left: 8px;
  border-radius: 100px;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
[type='radio']:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}
.order-panel {
  font: 15px/18px 'ProximaNovaRegular', sans-serif;
  color: #ffffff;
}
.order-footer {
  padding: 20px 0;
  button {
    display: inline-block;
    margin: 0;
    padding: 0 20px;
    border: none;
    height: 50px;
    background-color: #c70552;
    color: #ffffff;
    cursor: pointer;
    text-transform: uppercase;
    font: 14px/140% 'ProximaNovaRegular', sans-serif;
  }
}
.custom-checkbox {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
.custom-checkbox + label {
  display: inline-flex;
  align-items: center;
  user-select: none;
}
.custom-checkbox + label::before {
  content: '';
  display: inline-block;
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  flex-grow: 0;
  border: 1px solid #7d8b97;
  margin-right: 15px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
}
.custom-checkbox:checked + label::before {
  border-color: #7d8b97;
  background-color: #c70552;
  background-image: url('../../assets/images/order/check.svg');
}
/* стили при наведении курсора на checkbox */
.custom-checkbox:not(:disabled):not(:checked) + label:hover::before {
  border-color: #7d8b97;
}
</style>

<style lang="scss">
.size {
  font: 15px/18px 'ProximaNovaBold', sans-serif;
  color: #ffffff;
  margin-top: 26px;

  &__title {
    font: 15px/18px 'ProximaNovaBold', sans-serif;
  }
  &__text {
    margin-top: 10px;
    display: flex;
    align-items: center;
    font: 15px/18px 'ProximaNovaRegular', sans-serif;
    justify-content: space-between;
    span {
      position: relative;
      label.error {
        position: absolute;
        left: 5px;
        top: 100%;
        margin-top: 2px;
        white-space: nowrap;
      }
    }
  }
  &__group {
    display: flex;
    align-items: center;
    span {
      padding-right: 10px;
      &:last-child {
        padding-right: 0;
        padding-left: 10px;
      }
    }
  }
  &__lab {
    padding-right: 5px;
  }
  &__field {
    margin: 0 5px;
    padding-left: 15px;
    overflow: hidden;
    width: 101px;
    height: 40px;
    background: #272c30;
    border: 1px solid #7d8b97;
    box-sizing: border-box;
    border-radius: 3px;
    color: #ffffff;
    font: 15px/18px 'proxima_nova_regular', sans-serif;
    &--full {
      flex: 1;
      width: inherit;
    }
  }
}
.additionaly {
  font: 15px/18px 'ProximaNovaRegular', sans-serif;
  color: #c2cfd3;
  margin-top: 20px;
}
.file-input-container {
  display: inline-flex;
  padding: 5px;
  margin: 5px 0;
  align-items: center;
  border: 1px solid #7d8b97;
  border-radius: 4px;
  background-color: #272c30;
  padding: 5px 10px;
  box-sizing: border-box;
  color: #fff;
  width: 330px;
}

.file-input-label {
  margin-right: 10px;
  cursor: pointer;
  background-color: #272c30;
  padding: 5px 10px;
  border: 1px solid #7d8b97;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.file-input-label:hover {
  background-color: #4c4c4c;
}

.file-input {
  display: none;
}

.file-name {
  color: #fff;
  font-family: monospace;
  display: inline-block;
  max-width: 200px; /* Установите максимальную ширину по вашему усмотрению */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
}
.error-file {
  margin-top: 2px;
  white-space: nowrap;
}
</style>
